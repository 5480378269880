import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom'
import Breadcrumb from '../../../../components/common/breadcrumb';
import Datatable from '../../../../components/common/datatable'
import {connect} from "react-redux";
import {toast} from "react-toastify";
import {ApiService} from "../../../../services/ApiService";

const _ = require('lodash');

export class ProductTypeList extends Component {

	state = {
		product_types: this.props.product_types
	}

	componentWillReceiveProps = (nextProps)  => {
		this.setState({
			categories: nextProps.categories
		});
	}

	buildDatabase = (product_types) => {
		return product_types.map((type) => {
			return {
				id: type.id,
				label: type.label
			};
		})
	}

	deleteProductType = (product_type) => {
		if(window.confirm('Etes-vous sur de vouloir supprimer ce type de produit ?')) {
			ApiService.delete(`/admin/product_type/${product_type.id}`).then((res) => {
				if (res.status === 202) {
					this.props.removeProductType(product_type.id);
					toast.success("Le type de produit a bien été supprimer !")
				} else toast.error("Une erreur est survenu");
			});
		}
	}

    render() {
		const product_types = _.cloneDeep(this.state.product_types);
        return (
            <Fragment>
                <Breadcrumb title="Liste des types de produit" parent="Products" />
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header">
                            <h5>Détails des types de produits</h5>
                        </div>
                        <div className="card-body">
                            <div className="btn-popup pull-right">
                                <Link to="/products/types/create" className="btn btn-secondary">Créer un type de produit</Link>
                            </div>
                            <div className="clearfix"/>
                            <div id="batchDelete" className="category-table user-list order-table coupon-list-delete">
                                <Datatable
                                    multiSelectOption={false}
                                    myData={this.buildDatabase(product_types)}
                                    pageSize={10}
									pathEdit={'/products/types/edit/'}
									delete={this.deleteProductType}
                                    pagination={true}
                                    class="-striped -highlight"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
	return { product_types: state.product_types };
};

const mapDispatchToProps = (dispatch) => ({
	removeProductType: (id) => dispatch({ type: "PRODUCT_TYPE_REMOVE", id }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductTypeList);
