import React, {Component, Fragment} from 'react';
import ReactTable from 'react-table';
import "react-table/react-table.css";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Link} from "react-router-dom";


export class Datatable extends Component {
	constructor(props) {
		super(props)
		this.state = {
			checkedValues: [],
			myData: this.props.myData
		}
	}

	componentWillReceiveProps = (nextProps) => {
		this.setState({
			myData: nextProps.myData
		});
	}

	selectRow = (e, i) => {
		if (!e.target.checked) {
			this.setState({
				checkedValues: this.state.checkedValues.filter((item, j) => i !== item)
			});
		} else {
			this.state.checkedValues.push(i);
			this.setState({
				checkedValues: this.state.checkedValues
			})
		}
	}

	handleRemoveRow = () => {
		const selectedValues = this.state.checkedValues;
		const updatedData = this.state.myData.filter(function (el) {
			return selectedValues.indexOf(el.id) < 0;
		});
		this.setState({
			myData: updatedData
		})
		toast.success("Successfully Deleted !")
	};

	renderEditable = (cellInfo) => {
		return (
			<div
				style={{backgroundColor: "#fafafa"}}
				contentEditable
				suppressContentEditableWarning
				onBlur={e => {
					const data = [...this.state.myData];
					data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
					this.setState({myData: data});
				}}
				dangerouslySetInnerHTML={{
					__html: this.state.myData[cellInfo.index][cellInfo.column.id]
				}}
			/>
		);
	}

	Capitalize(str) {
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	render() {
		const {pageSize, myClass, multiSelectOption, pagination} = this.props;
		const {myData} = this.state

		const columns = [];
		for (var key in myData[0]) {
			columns.push(
				{
					Header: <b>{this.Capitalize(key.toString())}</b>,
					accessor: key,
					Cell: null,
					style: {
						textAlign: 'center'
					}
				});
		}

		if (multiSelectOption === true) {
			columns.push(
				{
					Header: <button className="btn btn-danger btn-sm btn-delete mb-0 b-r-4"
									onClick={
										(e) => {
											if (window.confirm('Are you sure you wish to delete this item?'))
												this.handleRemoveRow()
										}}>Delete</button>,
					id: 'delete',
					accessor: str => "delete",
					sortable: false,
					style: {
						textAlign: 'center'
					},
					Cell: (row) => (
						<div>
                            <span>
                                <input type="checkbox" name={row.original.id}
									   defaultChecked={this.state.checkedValues.includes(row.original.id)}
									   onChange={e => this.selectRow(e, row.original.id)}/>
                            </span>
						</div>
					)
				}
			)
		} else {
			columns.push(
				{
					Header: <b>Action</b>,
					id: 'delete',
					accessor: str => "delete",
					Cell: (row) => (
						<div>
                            <span onClick={() => this.props.delete(row.original)}>
                                <i className="fa fa-trash"
								   style={{width: 35, fontSize: 20, padding: 11, color: '#e4566e'}}
								/>
                            </span>
							<Link to={`${this.props.pathEdit}${row.original.id}`}>
								<span>
									<i className="fa fa-pencil"
									   style={{
										   width: 35,
										   fontSize: 20,
										   padding: 11,
										   color: 'rgb(40, 167, 69)'
									   }}/>
								</span>
							</Link>
						</div>
					),
					style: {
						textAlign: 'center'
					},
					sortable: false
				}
			)
		}

		return (
			<Fragment>
				<ReactTable
					previousText={'Précedent'}
					nextText={'Suivant'}
					rowsText={'lignes'}
					ofText={'de'}
					data={myData}
					columns={columns}
					defaultPageSize={pageSize}
					className={myClass}
					showPagination={pagination}
				/>
			</Fragment>
		)
	}
}

export default Datatable
