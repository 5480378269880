import React, {Component, Fragment} from 'react'
import Breadcrumb from '../../../components/common/breadcrumb';
import {ApiService} from "../../../services/ApiService";
import {InputText, SingleSelect, ImageSelect, Wysiwyg} from "../../../components/form";
import {toast} from "react-toastify";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";

export class CategoryAdd extends Component {

	state = {
		form: {visibility: 1},
		image: false,
		redirect: false
	}

	handleChange = (e) => {
		const {form} = this.state;
		form[e.target.id] = e.target.value;
		this.setState({form});
	}

	handleChangeImage = (file) => {
		const {form} = this.state;
		form.image = file;
		this.setState({form});
	}

	submitForm = (e) => {
		e.preventDefault();
		ApiService.post('/admin/category', this.state.form).then((res) => {
			if (res.status === 202) {
				toast.success("La categorie a bien été créer !")
				this.props.addCategory(res.data);
				this.setState({redirect: true})
			} else if (res.status === 400) {
				this.setState({errors: res.data.errors})
			}
		});
	}

	listFolder = (parent_id = 0, level = 0, folderList = [] ) => {
		if(level === 0)
			folderList.push({id: 0, value: 0, label: '--- Racine ---'});
		this.props.categories.forEach((category) => {
			if (category.category_parent === parent_id) {
				if (category.id !== this.state.form.id) {
					const sep = ' - - - - '.repeat(level);
					folderList.push({ value: category.id, label: sep + category.title });
					folderList = this.listFolder(category.id, level + 1, folderList);
				}
			}
		});
		return folderList;
	};

	handleChangeParent = (parent) => {
		const {form} = this.state;
		form['category_parent'] = parent.value;
		this.setState({form});
	};

	handleChangeVisibility = (visibility) => {
		const {form} = this.state;
		form['visibility'] = visibility;
		this.setState({form});
	};

	handleChangeDescription = (e) => {
		const {form} = this.state;
		form['description'] = e.editor.getData();
		this.setState({form});
	}

	getParentNode = () => {
		return this.listFolder().find((folder) => folder.value === this.state.form.parent_id);
	};

	cancel = () => {
		window.history.back();
	}

	render() {

		if (this.state.redirect)
			return (<Redirect to="/categories/list"/>);
		else
			return (
				<Fragment>
					<Breadcrumb title="Ajouter une catégorie" parent="Catégories"/>
					<form onSubmit={this.submitForm}>
						<div className="container-fluid">
							<div className="row">
								<div className="col-xl-9 offset-xl-1 col-sm-12">
									<div className="card">
										<div className="card-header">
											<h5>Détails de la Catégorie</h5>
										</div>
										<div className="card-body">
											<InputText id={"title"} label={'Titre'} required={true}
													   errors={this.state.errors} change={this.handleChange}/>
											<div className="form-group row">
												<label className="col-form-label col-xl-3">Visible</label>
												<div className="col-xl-3 m-checkbox-inline">
													<label className="col-6">
														<input className="radio_animated"
															   onClick={() => this.handleChangeVisibility(1)}
															   defaultChecked={this.state.form.visibility}
															   id="visibility" type="radio" name="visibility"/>
														Oui
													</label>
													<label className="col-6">
														<input className="radio_animated"
															   onClick={() => this.handleChangeVisibility(0)}
															   defaultChecked={!this.state.form.visibility}
															   id="visibility" type="radio"
															   name="visibility"/>
														Non
													</label>
												</div>
											</div>
											<SingleSelect
												id='category_parent'
												options={this.listFolder()}
												value={this.getParentNode()}
												change={this.handleChangeParent}
												errors={this.state.errors}
												label="Catégorie parent"
												placeholder="Sélectionner la catégorie parent"
											/>
											<Wysiwyg
												id={'description'}
												label={'Description'}
												value={this.state.form.description}
												errors={this.state.errors}
												change={this.handleChangeDescription} />
											<ImageSelect
												returnImage={this.handleChangeImage}
												id={'image'}
												errors={this.state.errors}
												small={"Taille conseillée : 440 x 220 pixels"}
											/>
										</div>
										<div className="card-footer">
											<div className="text-center">
												<button type="submit" className="btn btn-primary">Ajouter</button>
												<button type="button" className="btn btn-light"
														onClick={() => this.cancel()}>Annuler
												</button>
											</div>
										</div>
									</div>
								</div>

							</div>
						</div>
					</form>
				</Fragment>
			)
	}
}

const mapStateToProps = (state) => {
	return { categories: state.categories };
};

const mapDispatchToProps = (dispatch) => ({
	addCategory: (category) => dispatch({type: "CATEGORY_ADD", category}),
});


export default connect(mapStateToProps, mapDispatchToProps)(CategoryAdd);
