import React, {Component, Fragment} from 'react'

import TabsetProduct from './tabset-product';
import Breadcrumb from "../../../components/common/breadcrumb";
import {Button} from "reactstrap";
import {ApiService} from "../../../services/ApiService";
import {toast} from "react-toastify";


export class ProductAdd extends Component {

	state = {
		errors: [],
		form: {
			slug: '',
			enabled: 0,
			price: 0,
			new: 0,
			categories: [],
			max_cart: null,
			max_cart_month: null,
			max_cart_year: null
		}
	}

	setForm = (id, value) => {
		let form = this.state.form;
		form[id] = value;
		this.setState({form});
	}

	submitForm = () => {
		ApiService.post('/admin/product', this.state.form).then((res) => {
			if (res.status === 202) {
				toast.success("La produit a bien été créer !")
				//this.props.addPage(res.data);
				//this.setState({redirect: true})
			} else if (res.status === 400) {
				this.setState({errors: res.data.errors})
			}
		});
	}

	render() {
		return (
			<Fragment>
				<Breadcrumb title="Ajouter un produit" parent="Produits"/>
				<div className="container-fluid">
					<div className="row">
						<div className="col-xl-12">
							<div className="card profile-card">
								<div className="card-body">
									<TabsetProduct form={this.state.form} errors={this.state.errors} setForm={this.setForm}/>
								</div>
							</div>
						</div>

						<div className="col-xl-12 text-center">
							<Button onClick={() => this.submitForm()}>Ajouter ce produit</Button>
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}

export default ProductAdd
