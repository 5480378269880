import React from 'react';

import lock from '../../../assets/images/user/lock.png';
import {ApiService} from "../../../services/ApiService";
import {Auth} from "../../../services/AuthService";
import {Redirect} from "react-router-dom";

class Login extends React.Component {

	componentDidMount() {
		document.querySelector(".loader-wrapper").style = "display: none";
	}

	state = {
		notification: false,
		activeShow: true,
		redirect: false,
		form: {}
	}

	clickActive = (event) => {
		document.querySelector(".nav-link").classList.remove('show');
		event.target.classList.add('show');
	}

	handleChange = (e) => {
		const {form} = this.state;
		form[e.target.id] = e.target.value;
		this.setState({form});
	}

	submitSignin = event => {
		event.preventDefault();
		ApiService.post("/login", this.state.form).then(res => {
			if (res.status === 200) {
				localStorage.setItem("user", JSON.stringify(res.data));
				ApiService.setApiKey(res.data.token);

				Auth.authenticate(() => {
					this.setState({redirect: true});
				});

			} else
				this.showNotification();
		});
	};

	showNotification = () => {
		this.setState(() => ({notification: true}));
		window.setTimeout(() => {
			this.setState(() => ({notification: false}));
		}, 5000);
	};

	render() {
		if (this.state.redirect)
			return <Redirect to={{pathname: "/"}}/>;
		else
			return (
				<div className="auth-wrapper">
					<form onSubmit={this.submitSignin}>
						<div className="auth-content subscribe">
							<div className="card">
								<div className="row no-gutters">
									<div
										className="col-md-4 col-lg-6 d-none d-md-flex d-lg-flex theme-bg align-items-center justify-content-center">
										<img src={lock} alt="lock images" className="img-fluid"/>
									</div>
									<div className="col-md-8 col-lg-6">
										<div className="card-body text-center">
											<div className="row justify-content-center">
												<div className="col-sm-12">
													<h3 className="mb-4">Login</h3>
													<div className="input-group mb-3">
														<input required={true}
															   name="username"
															   type="text"
															   className="form-control"
															   onChange={this.handleChange}
															   placeholder="Identifiant IRM"
															   id="username"/>
													</div>
													<div className="input-group mb-4">
														<input required={true}
															   name="password"
															   id="password"
															   type="password"
															   className="form-control"
															   onChange={this.handleChange}
															   placeholder="Mot de passe"/>
													</div>
													<button className="btn btn-primary shadow-2 mb-4"
															type="submit">Connexion
													</button>
												</div>

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			);
	}
}

export default Login;