import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom'
import Breadcrumb from '../../../components/common/breadcrumb';
import Datatable from '../../../components/common/datatable'
import {connect} from "react-redux";
import user_logo from "../../../assets/images/user.png";
import {toast} from "react-toastify";
import {ApiService} from "../../../services/ApiService";

const _ = require('lodash');

export class UserList extends Component {

	state = {
		users: this.props.users
	}

	componentWillReceiveProps = (nextProps)  => {
		this.setState({
			users: nextProps.users
		});
	}

	buildDatabase = (users) => {
		return users.map((user) => {
			user['avatar'] = (<img src={user['avatar'] ? process.env.REACT_APP_MEDIA + user['avatar'] : user_logo} style={{ width: 50, height: 50 }} />)
			return user;
		})
	}

	deleteUser = (user) => {
		if(window.confirm('Etes-vous sur de vouloir supprimer cette utilisateur ?')) {
			ApiService.delete(`/admin/user/${user.id}`).then((res) => {
				if (res.status === 202) {
					this.props.removeUser(user.id);
					toast.success("L'utilisateur a bien été supprimer !")
				} else toast.error("Une erreur est survenu");
			});
		}
	}

    render() {
		const users = _.cloneDeep(this.state.users);
        return (
            <Fragment>
                <Breadcrumb title="Liste des utilisateurs" parent="Utilisateurs" />
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header">
                            <h5>Détails des utilisateurs</h5>
                        </div>
                        <div className="card-body">
                            <div className="btn-popup pull-right">
                                <Link to="/users/create" className="btn btn-secondary">Créer un utilisateur</Link>
                            </div>
                            <div className="clearfix"/>
                            <div id="batchDelete" className="category-table user-list order-table coupon-list-delete">
                                <Datatable
                                    multiSelectOption={false}
                                    myData={this.buildDatabase(users)}
                                    pageSize={10}
									pathEdit={'/users/edit/'}
									delete={this.deleteUser}
                                    pagination={true}
                                    class="-striped -highlight"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
	return { users: state.users };
};

const mapDispatchToProps = (dispatch) => ({
	removeUser: (id) => dispatch({ type: "USERS_REMOVE", id }),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
