import React, {Component, Fragment} from 'react'
import {Link} from 'react-router-dom'
//images import
import user from '../../../assets/images/user.png'

export class User_menu extends Component {

	state = {
		user: JSON.parse(localStorage.getItem("user_admin")),
	};

	render() {
		return (
			<Fragment>
				<li className="onhover-dropdown">
					<div className="media align-items-center">
						<img className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded"
							 src={this.state.user.avatar ? process.env.REACT_APP_MEDIA + this.state.user.avatar : user} alt="header-user"/>
						<h6 className={"ml-3 f-16"}>{this.state.user.firstname} {this.state.user.name}</h6>
					</div>
					<ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
						<li>
							<Link to={`${process.env.PUBLIC_URL}/settings/profile`}>
								<i data-feather="user"/>Edit Profile
							</Link>
						</li>
						<li><Link to={`/login`}><i data-feather="log-out"/>Déconnexion</Link></li>
					</ul>
				</li>
			</Fragment>
		)
	}
}

export default User_menu
