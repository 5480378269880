import React, {Component, Fragment} from 'react'
import Breadcrumb from '../../../../components/common/breadcrumb';
import {ApiService} from "../../../../services/ApiService";
import {InputText, SingleSelect} from "../../../../components/form";
import {toast} from "react-toastify";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";
import {typesAttribute} from "../../../../variables";

export class AttributeEdit extends Component {

	state = {
		form: this.props.attribute,
		redirect: false
	}

	handleChange = (e) => {
		const {form} = this.state;
		form[e.target.id] = e.target.value;
		if(e.target.id === 'name') {
			form['code'] = this.slug(e.target.value);
		}
		this.setState({form});
	}

	getTypeSelect = () => {
		return typesAttribute.find((type) => type.value === this.state.form.type);
	};

	submitForm = (e) => {
		e.preventDefault();
		console.log(this.state.form);
		ApiService.post('/admin/attribute/' + this.props.attribute.id, this.state.form).then((res) => {
			if (res.status === 202) {
				toast.success("L'attribute a bien été modifier !")
				this.props.editAttribute(res.data);
				this.setState({redirect: true})
			} else if (res.status === 400) {
				this.setState({errors: res.data.errors})
			}
		});
	}

	slug = (str) => {
		str = str.replace(/^\s+|\s+$/g, ''); // trim
		str = str.toLowerCase();

		// remove accents, swap ñ for n, etc
		var from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
		var to   = "aaaaaeeeeeiiiiooooouuuunc------";
		for (var i=0, l=from.length ; i<l ; i++) {
			str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
		}

		str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
			.replace(/\s+/g, '-') // collapse whitespace and replace by -
			.replace(/-+/g, '-'); // collapse dashes

		return str;
	};


	cancel = () => {
		window.history.back();
	}

	render() {
		if (this.state.redirect)
			return (<Redirect to="/products/attributes"/>);
		else
			return (
				<Fragment>
					<Breadcrumb title="Modifier l'attribut" parent="Produits"/>
					<form onSubmit={this.submitForm}>
						<div className="container-fluid">
							<div className="row">
								<div className="col-xl-9 offset-xl-1 col-sm-12">
									<div className="card">
										<div className="card-header">
											<h5>Détails de l'attribut</h5>
										</div>
										<div className="card-body">
											<InputText id={"name"} label={'Nom'} required={true}
													   errors={this.state.errors}
													   value={this.state.form.name}
													   change={this.handleChange}/>
											<InputText id={"code"} label={'Code'} required={true}
													   disabled
													   value={this.state.form.code}
													   errors={this.state.errors} change={this.handleChange}/>
											<SingleSelect
												id='type'
												options={typesAttribute}
												value={this.getTypeSelect()}
												change={this.handleChangeType}
												errors={this.state.errors}
												label="Type de la page"
												placeholder="Sélectionner le type de l'attribut"
											/>
										</div>
										<div className="card-footer">
											<div className="text-center">
												<button type="submit" className="btn btn-primary">Modifier</button>
												<button type="button" className="btn btn-light"
														onClick={() => this.cancel()}>Annuler
												</button>
											</div>
										</div>
									</div>
								</div>

							</div>
						</div>
					</form>
				</Fragment>
			)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		attribute: state.attributes.find(attribute => attribute.id === parseInt(ownProps.match.params.id))
	};
};

const mapDispatchToProps = (dispatch) => ({
	editAttribute: (attribute) => dispatch({type: "ATTRIBUTE_EDIT", attribute}),
});


export default connect(mapStateToProps, mapDispatchToProps)(AttributeEdit);
