import React, { Component } from "react";
import {BrowserRouter, Switch, Route} from "react-router-dom";
import FrontEnd from "./front-end";
import BackEnd from "./back-end/src";

export default class App extends Component {
	render() {
		return (
			<BrowserRouter>
				<Switch>
					<Route path='/admin' render={(props) => <BackEnd {...props} />} />
					<Route path='/' render={(props) => <FrontEnd {...props} />} />
				</Switch>
			</BrowserRouter>
		);
	}
}
