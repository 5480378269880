import React, {Component, Fragment} from 'react'
import Breadcrumb from '../../../../components/common/breadcrumb';
import {ApiService} from "../../../../services/ApiService";
import {InputText} from "../../../../components/form";
import {toast} from "react-toastify";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";

export class ProductTypeEdit extends Component {

	state = {
		form: this.props.product_type,
		redirect: false
	}

	handleChange = (e) => {
		const {form} = this.state;
		form[e.target.id] = e.target.value;
		this.setState({form});
	}

	submitForm = (e) => {
		e.preventDefault();
		console.log(this.state.form);
		ApiService.post('/admin/product_type/' + this.props.product_type.id, this.state.form).then((res) => {
			if (res.status === 202) {
				toast.success("Le type de produit a bien été modifier !")
				this.props.editProductType(res.data);
				this.setState({redirect: true})
			} else if (res.status === 400) {
				this.setState({errors: res.data.errors})
			}
		});
	}

	cancel = () => {
		window.history.back();
	}

	render() {

		if (this.state.redirect)
			return (<Redirect to="/products/types"/>);
		else
			return (
				<Fragment>
					<Breadcrumb title="Modifier le type de produit" parent="Produits"/>
					<form onSubmit={this.submitForm}>
						<div className="container-fluid">
							<div className="row">
								<div className="col-xl-9 offset-xl-1 col-sm-12">
									<div className="card">
										<div className="card-header">
											<h5>Détails du type de produit</h5>
										</div>
										<div className="card-body">
											<InputText id={"label"} label={'Titre'} required={true}
													   errors={this.state.errors}
													   value={this.state.form.label}
													   change={this.handleChange}/>
										</div>
										<div className="card-footer">
											<div className="text-center">
												<button type="submit" className="btn btn-primary">Modifier</button>
												<button type="button" className="btn btn-light"
														onClick={() => this.cancel()}>Annuler
												</button>
											</div>
										</div>
									</div>
								</div>

							</div>
						</div>
					</form>
				</Fragment>
			)
	}
}

const mapStateToProps = (state, ownProps) => {
	console.log(state.product_types.find(product_type => product_type.id === parseInt(ownProps.match.params.id)));
	return {
		product_type: state.product_types.find(product_type => product_type.id === parseInt(ownProps.match.params.id))
	};
};

const mapDispatchToProps = (dispatch) => ({
	editProductType: (product_type) => dispatch({type: "PRODUCT_TYPE_EDIT", product_type}),
});


export default connect(mapStateToProps, mapDispatchToProps)(ProductTypeEdit);
