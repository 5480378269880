import React, {Component} from 'react';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import {DollarSign, File, Folder, Image, ShoppingCart} from 'react-feather'
import {ImageSelect, InputText, SingleSelect, Wysiwyg} from "../../../components/form";
import InputTextArea from "../../../components/form/TextArea";
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import {connect} from "react-redux";

export class TabsetProduct extends Component {


	state = {
		checked: [],
		expanded: [],
	};

	listCategories = (parent = 0) => {
		return this.props.categories
			.filter(category => category.category_parent === parent)
			.map(category => {
				return {
					value: category.id,
					label: category.title,
					children: this.listCategories(category.id)
				}
			});
	}

	onCheck = (checked) => {
		this.setState({checked});
		this.props.setForm("categories", checked);
	}

	onExpand = (expanded) => {
		this.setState({expanded});
	}

	handleChange = (e) => {
		this.props.setForm(e.target.id, e.target.value);
	}

	listLimit = () => {
		return [
			{value: 0, label: 'Aucune Limite'},
			{value: 1, label: '1'},
			{value: 2, label: '2'},
			{value: 3, label: '3'},
			{value: 4, label: '4'},
			{value: 5, label: '5'},
			{value: 6, label: '6'},
			{value: 7, label: '7'},
			{value: 8, label: '8'},
			{value: 9, label: '9'},
			{value: 10, label: '10'},
		]
	};


	listTypes = () => {
		return this.props.product_types.map(type => {
			return {value: type.id, label: type.label};
		});
	};

	getType = () => {
		return this.listTypes().find((type) => type.value === this.props.form.type);
	};

	getMaxCart = () => {
		return this.listLimit().find((type) => type.value === this.props.form.max_cart);
	};

	getMaxCartMonth = () => {
		return this.listLimit().find((type) => type.value === this.props.form.max_cart_month);
	};

	getMaxCartYear = () => {
		return this.listLimit().find((type) => type.value === this.props.form.max_cart_year);
	};

	handleChangeSelect = (id, el) => {
		this.props.setForm(id, el.value);
	};

	handleChangeRadio = (id, value) => {
		this.props.setForm(id, value);
	};

	handleChangeImage = (file) => {
		this.props.setForm('image', file);
	}

	render() {
		return (
			<div>
				<Tabs>
					<TabList className="nav nav-tabs tab-coupon">
						<Tab className="nav-link"><File className="mr-2"/>Détails</Tab>
						<Tab className="nav-link"><DollarSign className="mr-2"/>Prix</Tab>
						<Tab className="nav-link"><Folder className="mr-2"/>Catégories</Tab>
						<Tab className="nav-link"><Image className="mr-2"/>Medias</Tab>
						<Tab className="nav-link"><ShoppingCart className="mr-2"/>Condition de vente</Tab>
					</TabList>

					<TabPanel>
						<InputText id={"name"} label={'Nom du produit'} required={true}
								   value={this.props.form.name}
								   errors={this.props.errors} change={this.handleChange}/>
						<InputText id={"slug"} label={'Slug'} required={true}
								   disabled
								   value={this.props.form.slug}
								   errors={this.props.errors} change={this.handleChange}/>
						<SingleSelect
							id='type'
							options={this.listTypes()}
							value={this.getType()}
							change={this.handleChangeType}
							errors={this.props.errors}
							label="Type du produit"
							placeholder="Type du produit"
						/>
						<div className="form-group row">
							<label className="col-form-label col-xl-3">Visible</label>
							<div className="col-xl-3 m-checkbox-inline">
								<label className="col-6">
									<input className="radio_animated"
										   onClick={() => this.handleChangeRadio('enabled', 1)}
										   defaultChecked={this.props.form.enabled}
										   id="enabled" type="radio" name="enabled"/>
									Oui
								</label>
								<label className="col-6">
									<input className="radio_animated"
										   onClick={() => this.handleChangeRadio('enabled', 0)}
										   defaultChecked={!this.props.form.enabled}
										   id="enabled" type="radio"
										   name="enabled"/>
									Non
								</label>
							</div>
						</div>
						<InputTextArea id={"short_description"} label={'Description Courte'}
									   value={this.props.form.description}
									   errors={this.props.errors} change={this.handleChange}/>
						<Wysiwyg id={"description"} label={'Description'}
								 value={this.props.form.description}
								 errors={this.props.errors} change={this.handleChange}/>
						<ImageSelect
							returnImage={this.handleChangeImage}
							id={'image'}
							errors={this.props.errors}
						/>

					</TabPanel>
					<TabPanel>
						<div className="form-group row">
							<label className="col-form-label col-xl-3">Nouveauté</label>
							<div className="col-xl-3 m-checkbox-inline">
								<label className="col-6">
									<input className="radio_animated"
										   onClick={() => this.handleChangeRadio('new', 1)}
										   defaultChecked={this.props.form.new}
										   id="new" type="radio" name="new"/>
									Oui
								</label>
								<label className="col-6">
									<input className="radio_animated"
										   onClick={() => this.handleChangeRadio('new', 0)}
										   defaultChecked={!this.props.form.new}
										   id="new" type="radio"
										   name="new"/>
									Non
								</label>
							</div>
						</div>
						<div className="form-group row">
							<label className="col-form-label col-xl-3">Promotion</label>
							<div className="col-xl-3 m-checkbox-inline">
								<label className="col-6">
									<input className="radio_animated"
										   onClick={() => this.handleChangeRadio('offer', 0)}
										   defaultChecked={this.props.form.offer}
										   id="enabled" type="radio" name="enabled"/>
									Oui
								</label>
								<label className="col-6">
									<input className="radio_animated"
										   onClick={() => this.handleChangeRadio('offer', 1)}
										   defaultChecked={!this.props.form.offer}
										   id="enabled" type="radio"
										   name="enabled"/>
									Non
								</label>
							</div>
						</div>
						<InputText id={"price"} label={'Prix'} required={true}
								   value={this.props.price}
								   errors={this.props.errors} change={this.handleChange}/>
						<InputText id={"original_price"} label={'Prix Original'} required={true}
								   value={this.props.original_price}
								   errors={this.props.errors} change={this.handleChange}/>
					</TabPanel>
					<TabPanel>
						<div style={{all: 'initial'}}>
							<CheckboxTree
								checked={this.props.form.categories}
								expanded={this.state.expanded}
								noCascade
								nodes={this.listCategories()}
								onCheck={this.onCheck}
								onExpand={this.onExpand}
							/>
						</div>
					</TabPanel>
					<TabPanel>
						<div className={"row"}>
							<div className={'col-6'}>
								<ImageSelect
									returnImage={this.handleChangeImage}
									id={'image[]'}
									errors={this.props.errors}
								/>
							</div>
							<div className={'col-6'}>
								<ImageSelect
									returnImage={this.handleChangeImage}
									id={'image[]'}
									errors={this.props.errors}
								/>
							</div>
							<div className={'col-6'}>
								<ImageSelect
									returnImage={this.handleChangeImage}
									id={'image[]'}
									errors={this.props.errors}
								/>
							</div>
							<div className={'col-6'}>
								<ImageSelect
									returnImage={this.handleChangeImage}
									id={'image[]'}
									errors={this.props.errors}
								/>
							</div>
							<div className={'col-6'}>
								<ImageSelect
									returnImage={this.handleChangeImage}
									id={'image[]'}
									errors={this.props.errors}
								/>
							</div>
						</div>
					</TabPanel>
					<TabPanel>
						<SingleSelect
							id='max_cart'
							options={this.listLimit()}
							value={this.getMaxCart()}
							change={(el) => this.handleChangeSelect('max_cart_month', el)}
							errors={this.props.errors}
							label="Maximum de produit dans le panier"
							placeholder="Maximum de produit dans le panier"
						/>

						<SingleSelect
							id='max_cart_month'
							options={this.listLimit()}
							value={this.getMaxCartMonth()}
							change={(el) => this.handleChangeSelect('max_cart_month', el)}
							errors={this.props.errors}
							label="Limite d'achat par mois"
							placeholder="Nombre de produit max dans le panier"
						/>

						<SingleSelect
							id='max_cart_year'
							options={this.listLimit()}
							value={this.getMaxCartYear()}
							change={(el) => this.handleChangeSelect('max_cart_year', el)}
							errors={this.props.errors}
							label="Limite d'achat par an"
							placeholder="Nombre de produit max dans le panier"
						/>
					</TabPanel>
				</Tabs>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		categories: state.categories,
		product_types: state.product_types
	};
};

export default connect(mapStateToProps)(TabsetProduct);
