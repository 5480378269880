import React, { Component } from 'react'
import Sidebar from './common/sidebar_components/sidebar';
import Right_sidebar from './common/right-sidebar';
import Footer from './common/footer';
import Header from './common/header_components/header';

// Service
import {ApiService} from "../services/ApiService";
import {Auth} from "../services/AuthService";
import {NotificationService} from "../services/NotificationService";
import {connect} from "react-redux";

export class App extends Component {

	state = {
		loading: true
	};

	componentDidMount() {
		this.getConfig();
	}

	getConfig = () => {
		this.setState({ loading: true});
		ApiService.get("/admin/config").then((res) => {
			if (res.status === 200) {
				document.querySelector(".loader-wrapper").style = "display: none";
				this.props.users(res.data.users);
				this.props.categories(res.data.categories);
				this.props.pages(res.data.pages);
				this.props.product_types(res.data.product_types);
				this.props.attributes(res.data.attributes);
				this.setState({loading: false})
			} else if (res.status === 401) Auth.signout();
			else NotificationService.call("danger", "Récuperation de la config", "Une erreur Api est survenue");
		});
	};

    render() {
        return !this.state.loading ? (
            <div>
                <div className="page-wrapper" >
                    <Header />
                    <div className="page-body-wrapper">
                        <Sidebar />
                        <Right_sidebar />
                        <div className="page-body">
                            {this.props.children}
                        </div>
                            <Footer />
                    </div>
                </div>
            </div>
        ) : null
    }
}

const mapDispatchToProps = (dispatch) => ({
	users: (users) => dispatch({ type: "USERS_LIST", users }),
	categories: (categories) => dispatch({ type: "CATEGORY_LIST", categories }),
	pages: (pages) => dispatch({ type: "PAGE_LIST", pages }),
	product_types: (product_types) => dispatch({ type: "PRODUCT_TYPE_LIST", product_types }),
	attributes: (attributes) => dispatch({ type: "ATTRIBUTE_LIST", attributes })
});

export default connect(null, mapDispatchToProps)(App);
