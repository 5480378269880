import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom'
import Breadcrumb from '../../../components/common/breadcrumb';
import Datatable from '../../../components/common/datatable'
import {connect} from "react-redux";
import user_logo from "../../../assets/images/user.png";
import {toast} from "react-toastify";
import {ApiService} from "../../../services/ApiService";

const _ = require('lodash');

export class CategoryList extends Component {

	state = {
		categories: this.props.categories
	}

	componentWillReceiveProps = (nextProps)  => {
		this.setState({
			categories: nextProps.categories
		});
	}

	buildDatabase = (categories) => {
		return categories.map((category) => {
			let parent = 'Racine';
			if(category.category_parent)
				parent = this.props.categories.find((cat) => cat.id === category.category_parent).title
			return {
				id: category.id,
				image: (<img src={process.env.REACT_APP_MEDIA + category.thumbnail} />),
				title: category.title,
				visibility: category.visibility ? <i className="fa fa-circle font-success f-12"/> : <i className="fa fa-circle font-error f-12"/>,
				parent: parent,

			};
		})
	}

	deleteCategory = (category) => {
		if(window.confirm('Etes-vous sur de vouloir supprimer cette catégorie ?')) {
			ApiService.delete(`/admin/category/${category.id}`).then((res) => {
				if (res.status === 202) {
					this.props.removeCategory(category.id);
					toast.success("La catégorie a bien été supprimer !")
				} else toast.error("Une erreur est survenu");
			});
		}
	}

    render() {
		const categories = _.cloneDeep(this.state.categories);
        return (
            <Fragment>
                <Breadcrumb title="Liste des Catégories" parent="Catégories" />
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header">
                            <h5>Détails des catégories</h5>
                        </div>
                        <div className="card-body">
                            <div className="btn-popup pull-right">
                                <Link to="/categories/create" className="btn btn-secondary">Créer une catégorie</Link>
                            </div>
                            <div className="clearfix"/>
                            <div id="batchDelete" className="category-table user-list order-table coupon-list-delete">
                                <Datatable
                                    multiSelectOption={false}
                                    myData={this.buildDatabase(categories)}
                                    pageSize={10}
									pathEdit={'/categories/edit/'}
									delete={this.deleteCategory}
                                    pagination={true}
                                    class="-striped -highlight"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
	return { categories: state.categories };
};

const mapDispatchToProps = (dispatch) => ({
	removeCategory: (id) => dispatch({ type: "CATEGORY_REMOVE", id }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryList);
