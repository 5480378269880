import React, { Component } from "react";

//Reactstrap Components
import { Row, Col, FormGroup } from "reactstrap";

//Utils
import ReactDropzone from "react-dropzone";

export default class ImageSelect extends Component {
	state = {
		image: "",
		upload: "",
		file: null,
	};

	componentDidMount() {
		if (this.props.image) this.setState({ image: this.props.image });
	}

	componentDidUpdate(prevProps) {
		if (prevProps.image !== this.props.image)
			this.setState({
				image: this.props.image,
				upload: "",
				file: null,
			});
	}

	onPreviewDrop = (files) => {
		if (files.length) {
			let reader = new FileReader();
			reader.onload = (e) => {
				this.setState({
					file: e.target.result,
					upload: e.target.result,
				});
			};
			this.props.returnImage(files[0]);
			reader.readAsDataURL(files[0]);
		} else alert("Veuillez insérer une Image");
	};

	isError = (id) => {
		const { errors } = this.props;
		if (errors)
			if (errors.find((x) => x.property === id)) return true;
			else return false;
	};

	returnError = (id) => {
		const { errors } = this.props;
		return errors.find((x) => x.property === id).message;
	};

	render() {
		const { label, file, image, upload } = this.state;
		const { id } = this.props;

		const previewStyle = {
			display: "block",
			maxWidth: 250,
			maxHeight: 150,
		};

		return (
			<Row className='align-items-center'>
				<Col xs={file || image ? 6 : 12}>
					<FormGroup>
						<label
							className={`col-form-label ${this.isError(id) ? "text-danger" : ""}`}
							htmlFor={id}>
							{file || image ? "Changer l'image" : "Ajouter une image"}
						</label>
						{this.props.small ? <div><small>{this.props.small}</small></div>: null }
						<div className='app'>
							<ReactDropzone accept='image/*' onDrop={this.onPreviewDrop}>
								{({ getRootProps, getInputProps }) => (
									<section className='drop_csv'>
										<div className='drop_csv_enter' {...getRootProps()}>
											<div className='drop_csv_border'>
												<input {...getInputProps()} />
												<span className='fa fa-cloud-upload-alt' />
												<strong>DRAG & DROP</strong>
												<button onClick={(e) => e.preventDefault()}>OU SELECTIONNER</button>
											</div>
										</div>
									</section>
								)}
							</ReactDropzone>
						</div>
						{this.isError(id) ? <div className='invalid-feedback message-error'>{this.returnError(id)}</div> : null}
					</FormGroup>
				</Col>
				{file || image ? (
					<Col xs='6' className='pr-3'>
						<FormGroup>
							<label
								className={"col-form-label"}
								htmlFor={id}>
								{label}
							</label>
							<img
								alt='Preview'
								src={upload ? upload : image}
								style={previewStyle}
							/>
							{this.isError(id) ? <div className='invalid-feedback'>{this.returnError(id)}</div> : null}
						</FormGroup>
					</Col>
				) : null}
			</Row>
		);
	}
}
