const users = (state = [], action) => {
	switch (action.type) {
		case "USERS_LIST":
			return action.users;
		case "USERS_ADD":
			state = [...state, action.user];
			return state;
		case "USERS_EDIT":
			return state.map((user) => (user.id === action.user.id ? action.user : user));
		case "USERS_REMOVE":
			return state.filter((user) => {
				return action.id !== user.id;
			});
		default:
			return state;
	}
};

export default users;
