import React, { Component } from 'react'

import user from "../../../assets/images/user.png";

export class User_panel extends Component {

	state = {
		user: JSON.parse(localStorage.getItem("user_admin")),
	};

    render() {
        return (
            <div>
                <div className="sidebar-user text-center">
                    <div>
						<img className="img-60 rounded-circle lazyloaded blur-up"
							 src={this.state.user.avatar ? process.env.REACT_APP_MEDIA + this.state.user.avatar : user} alt="#" />
                    </div>
                    <h6 className="mt-3 f-14">{this.state.user.firstname} {this.state.user.name}</h6>
                    <p>{this.state.user.job}</p>
                </div>
            </div>
        )
    }
}

export default User_panel

