import React, { Component } from "react";

//Reactstrap Components
import { FormGroup } from "reactstrap";

//Components
import CKEditors from "react-ckeditor-component";

export default class Wysiwyg extends Component {
	isError = (id) => {
		const { errors } = this.props;
		if (errors)
			if (errors.find((x) => x.property === id)) return true;
			else return false;
	};

	returnError = (id) => {
		const { errors } = this.props;
		return errors.find((x) => x.property === id).message;
	};

	render() {
		const { id, label, value, change } = this.props;

		return (
			<FormGroup>
				{label ? (
					<>
						<span>
							<label
								className={"col-form-label"}
								htmlFor={id}>
								{label}
							</label>
						</span>
					</>
				) : null}

				<CKEditors
					activeclassName="p10"
					content={value}
					events={{
						"change": change
					}}
				/>
				{this.isError(id) ? <div className='invalid-feedback message-error'>{this.returnError(id)}</div> : null}
			</FormGroup>
		);
	}
}
