import React, {Component} from "react";

export default class Notification extends Component {
	render() {
		return (this.props.display ? (
				<div className='notifications'>
					<div className='notification error'>
						<div className='left'>
							<div className='icon message-alert'>
								<i className='fa fa-exclamation-circle' aria-hidden='true'/>
							</div>
						</div>
						<div className='right'>
							<h2>Une erreur est survenue</h2>
							<p>Votre login / mot de passe est erroné</p>
						</div>
					</div>
				</div>
			) : null);
	}
}