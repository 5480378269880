import React, {Component, Fragment} from 'react'
import Breadcrumb from '../../../../components/common/breadcrumb';
import {ApiService} from "../../../../services/ApiService";
import {InputText} from "../../../../components/form";
import {toast} from "react-toastify";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";

export class ProductTypeAdd extends Component {

	state = {
		redirect: false,
		form: {},
	}

	handleChange = (e) => {
		const {form} = this.state;
		form[e.target.id] = e.target.value;
		this.setState({form});
	}

	handleChangeImage = (file) => {
		const {form} = this.state;
		form.image = file;
		this.setState({form});
	}

	submitForm = (e) => {
		e.preventDefault();
		ApiService.post('/admin/product_type', this.state.form).then((res) => {
			if (res.status === 202) {
				toast.success("La type produit a bien été créer !")
				this.props.addProductType(res.data);
				this.setState({redirect: true})
			} else if (res.status === 400) {
				this.setState({errors: res.data.errors})
			}
		});
	}

	cancel = () => {
		window.history.back();
	}

	render() {
		if (this.state.redirect)
			return (<Redirect to="/products/types"/>);
		else
			return (
				<Fragment>
					<Breadcrumb title="Ajouter une catégorie" parent="Catégories"/>
					<form onSubmit={this.submitForm}>
						<div className="container-fluid">
							<div className="row">
								<div className="col-xl-9 offset-xl-1 col-sm-12">
									<div className="card">
										<div className="card-header">
											<h5>Détails du type de produit</h5>
										</div>
										<div className="card-body">
											<InputText id={"label"} label={'Nom'} required={true}
													   errors={this.state.errors} change={this.handleChange}/>
										</div>
										<div className="card-footer">
											<div className="text-center">
												<button type="submit" className="btn btn-primary">Ajouter</button>
												<button type="button" className="btn btn-light"
														onClick={() => this.cancel()}>Annuler
												</button>
											</div>
										</div>
									</div>
								</div>

							</div>
						</div>
					</form>
				</Fragment>
			)
	}
}

const mapDispatchToProps = (dispatch) => ({
	addProductType: (product_type) => dispatch({type: "PRODUCT_TYPE_ADD", product_type}),
});


export default connect(null, mapDispatchToProps)(ProductTypeAdd);
