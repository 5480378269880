import React, {Component, Fragment} from 'react'

// Components
import {User} from 'react-feather';
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import { Redirect } from "react-router-dom";
import Notification from "./components/Notification";

// Assets
import stats from '../../../assets/images/dashboard/stats.png';

// Services
import {ApiService} from "../../../services/ApiService";
import {Auth} from "../../../services/AuthService";

export class Login extends Component {

	state = {
		notification: false,
		activeShow: true,
		redirect: false,
		form: {}
	}

	clickActive = (event) => {
		document.querySelector(".nav-link").classList.remove('show');
		event.target.classList.add('show');
	}

	handleChange = (e) => {
		const {form} = this.state;
		form[e.target.id] = e.target.value;
		this.setState({form});
	}

	submitSignin = event => {
		event.preventDefault();
		ApiService.post("/admin/login", this.state.form).then(res => {
			if (res.status === 200) {
				localStorage.setItem("user_admin", JSON.stringify(res.data));
				ApiService.setApiKey(res.data.token);

				Auth.authenticate(() => {
					this.setState({redirect: true});
				});

			} else
				this.showNotification();
		});
	};

	showNotification = () => {
		this.setState(() => ({notification: true}));
		window.setTimeout(() => {
			this.setState(() => ({notification: false}));
		}, 5000);
	};

	render() {
		document.querySelector(".loader-wrapper").style = "display: none";
		if (this.state.redirect)
			return <Redirect to={{ pathname: "/" }}/>;
		else
			return (
				<Fragment>
					<div className="page-wrapper">
						<div className="authentication-box">
							<Notification display={this.state.notification}/>
							<div className="container">
								<div className="row">
									<div className="col-md-5 p-0 card-left">
										<div className="card bg-primary">
											<div className="svg-icon">
												<img src={stats} className="Img-fluid"/>
											</div>
											<div>
												<div>
													<h3>Bienvenue sur<br/>le Back-Office</h3>
													<p>Interface de gestion du nouveau site du CSE de
														Free-Distribution</p>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-7 p-0 card-right">
										<div className="card tab2-card">
											<div className="card-body">
												<div className={"auth-form"}>
													<Fragment>
														<Tabs>
															<TabList className="nav nav-tabs tab-coupon">
																<Tab className="nav-link"
																	 onClick={(e) => this.clickActive(e)}><User/>Login</Tab>
															</TabList>

															<TabPanel>
																<form className="form-horizontal"
																	  onSubmit={this.submitSignin}>
																	<div className="form-group">
																		<input required="true"
																			   name="username"
																			   type="text"
																			   className="form-control"
																			   onChange={this.handleChange}
																			   placeholder="Login"
																			   id="username"/>
																	</div>
																	<div className="form-group">
																		<input required="true"
																			   name="password"
																			   id="password"
																			   type="password"
																			   className="form-control"
																			   onChange={this.handleChange}
																			   placeholder="Mode de passe"/>
																	</div>
																	<div className="form-button">
																		<button className="btn btn-primary"
																				type="submit">
																			Connexion
																		</button>
																	</div>
																</form>
															</TabPanel>
														</Tabs>
													</Fragment>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Fragment>
			)
	}
}

export default Login
