const pages = (state = [], action) => {
	switch (action.type) {
		case "PAGE_LIST":
			return action.pages;
		case "PAGE_ADD":
			state = [...state, action.page];
			return state;
		case "PAGE_EDIT":
			return state.map((page) => (page.id === action.page.id ? action.page : page));
		case "PAGE_REMOVE":
			return state.filter((page) => {
				return action.id !== page.id;
			});
		default:
			return state;
	}
};

export default pages;
