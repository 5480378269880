import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom'
import Breadcrumb from '../../../components/common/breadcrumb';
import Datatable from '../../../components/common/datatable'
import {connect} from "react-redux";
import {toast} from "react-toastify";
import {ApiService} from "../../../services/ApiService";
import {typePages} from "../../../constants/type_pages";

const _ = require('lodash');

export class PageList extends Component {

	state = {
		pages: this.props.pages
	}

	componentWillReceiveProps = (nextProps)  => {
		this.setState({
			pages: nextProps.pages
		});
	}

	buildDatabase = (pages) => {
		return pages.map((page) => {
			const type = typePages.find((type) => type.value === page.type).label
			return {
				id: page.id,
				title: page.title,
				type: type,
				visibility: page.visibility ? <i className="fa fa-circle font-success f-12"/> : <i className="fa fa-circle font-error f-12"/>,
			};
		})
	}

	deletePage = (page) => {
		if(window.confirm('Etes-vous sur de vouloir supprimer cette page ?')) {
			ApiService.delete(`/admin/page/${page.id}`).then((res) => {
				if (res.status === 202) {
					this.props.removePage(page.id);
					toast.success("La page a bien été supprimer !")
				} else toast.error("Une erreur est survenu");
			});
		}
	}

    render() {
		const pages = _.cloneDeep(this.state.pages);
        return (
            <Fragment>
                <Breadcrumb title="Liste des Pages" parent="Pages" />
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header">
                            <h5>Détails des pages</h5>
                        </div>
                        <div className="card-body">
                            <div className="btn-popup pull-right">
                                <Link to="/pages/create" className="btn btn-secondary">Créer une page</Link>
                            </div>
                            <div className="clearfix"/>
                            <div id="batchDelete" className="category-table user-list order-table coupon-list-delete">
                                <Datatable
                                    multiSelectOption={false}
                                    myData={this.buildDatabase(pages)}
                                    pageSize={10}
									pathEdit={'/pages/edit/'}
									delete={this.deletePage}
                                    pagination={true}
                                    class="-striped -highlight"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
	return { pages: state.pages };
};

const mapDispatchToProps = (dispatch) => ({
	removePage: (id) => dispatch({ type: "PAGE_REMOVE", id }),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageList);
