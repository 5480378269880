const categories = (state = [], action) => {
	switch (action.type) {
		case "CATEGORY_LIST":
			return action.categories;
		case "CATEGORY_ADD":
			state = [...state, action.category];
			return state;
		case "CATEGORY_EDIT":
			return state.map((category) => (category.id === action.category.id ? action.category : category));
		case "CATEGORY_REMOVE":
			return state.filter((category) => {
				return action.id !== category.id;
			});
		default:
			return state;
	}
};

export default categories;
