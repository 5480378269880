import { combineReducers } from "redux";

//Reducers
import users from "./users";
import categories from "./categories";
import pages from "./pages";
import product_types from "./product_types";
import attributes from "./attributes";

export default combineReducers({
	users,
	categories,
	pages,
	product_types,
	attributes
});
