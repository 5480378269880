import React, {Component} from "react";

export default class InputText extends Component {
	isError = (id) => {
		const {errors} = this.props;
		if (errors)
			if (errors.find((x) => x.property === id)) return true;
			else return false;
	};

	returnError = (id) => {
		const {errors} = this.props;
		return errors.find((x) => x.property === id).message;
	};

	render() {
		const {id, value, required, name, placeholder, change, disabled, label} = this.props;

		return (
			<div className="form-group row">
				<label
					className={"col-form-label col-xl-3 col-md-4"}>{/*required ? <span>*&nbsp;</span> : ''*/}{label}</label>
				<div className={"col-xl-8 col-md-7"}>
					<input
						className={`${this.isError(id) ? "is-invalid " : ""} form-control`}
						id={id}
						name={name}
						type="text"
						required={required}
						disabled={disabled}
						placeholder={placeholder}
						onChange={change}
						value={value}
					/>
				</div>
				{this.isError(id) ? <div className='invalid-feedback message-error'>{this.returnError(id)}</div> : null}
			</div>
		);
	}
}
