const product_types = (state = [], action) => {
	switch (action.type) {
		case "PRODUCT_TYPE_LIST":
			return action.product_types;
		case "PRODUCT_TYPE_ADD":
			state = [...state, action.product_type];
			return state;
		case "PRODUCT_TYPE_EDIT":
			return state.map((product_type) => (product_type.id === action.product_type.id ? action.product_type : product_type));
		case "PRODUCT_TYPE_REMOVE":
			return state.filter((product_type) => {
				return action.id !== product_type.id;
			});
		default:
			return state;
	}
};

export default product_types;
