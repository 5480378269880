const attributes = (state = [], action) => {
	switch (action.type) {
		case "ATTRIBUTE_LIST":
			return action.attributes;
		case "ATTRIBUTE_ADD":
			state = [...state, action.attribute];
			return state;
		case "ATTRIBUTE_EDIT":
			return state.map((attribute) => (attribute.id === action.attribute.id ? action.attribute : attributes));
		case "ATTRIBUTE_REMOVE":
			return state.filter((attribute) => {
				return action.id !== attribute.id;
			});
		default:
			return state;
	}
};

export default attributes;
