import React, { Component } from "react";

//Utils
import Select, { components } from "react-select";

const { Option } = components;
const IconOption = (props) => (
	<Option {...props} className='inline-block'>
		<div className='select2-icon float-left'>{props.data.icon}</div>
		{props.data.label}
	</Option>
);

export default class SingleSelect extends Component {

	isError = (id) => {
		const {errors} = this.props;
		if (errors)
			if (errors.find((x) => x.property === id)) return true;
			else return false;
	};

	returnError = (id) => {
		const {errors} = this.props;
		return errors.find((x) => x.property === id).message;
	};

	render() {
		const { options, id, value, defaultValue, change, placeholder, disabled, label, error } = this.props;

		const selectStyles = {
			menu: (base) => ({
				...base,
				zIndex: "100 !important",
				color: "black",
			}),
		};

		return (
			<div className='form-group select2 row'>
				{label ? (
					<label className={`col-form-label col-xl-3 col-md-4 ${error ? " text-danger" : ""}`} htmlFor={id}>
						{label}
					</label>
				) : null}

				<Select
					className={`col-xl-8 col-md-7 ${error ? " reactselect-invalid" : ""}`}
					id={id}
					name={id}
					options={options}
					value={value}
					defaultValue={defaultValue}
					onChange={change}
					placeholder={placeholder}
					isDisabled={disabled}
					components={{ Option: IconOption }}
					styles={selectStyles}
				/>

				{this.isError(id) ? <div className='invalid-feedback message-error'>{this.returnError(id)}</div> : null}
			</div>
		);
	}
}
