//Utils
import axios from "axios";

//Variables
import { AUTH_TOKEN } from "./AuthService";

export const ApiService = {
	urlApi: process.env.REACT_APP_API_URL,
	apiKey: AUTH_TOKEN ? JSON.parse(AUTH_TOKEN).token : "",

	get(path, data = {}) {
		return axios
			.get(this.urlApi + path, {
				params: data,
				headers: { ApiKey: this.apiKey },
				withCredentials: true,
			})
			.then(function (response) {
				return response;
			})
			.catch(function (error) {
				return error.response;
			});
	},

	delete(path) {
		return axios
			.delete(this.urlApi + path, { headers: { ApiKey: this.apiKey }, withCredentials: true })
			.then(function (response) {
				return response;
			})
			.catch(function (error) {
				console.log(error);
			});
	},

	post(path, data, content = false) {
		let formData = new FormData()
		for (const [key, value] of Object.entries(data)) {
			formData.append(key, value)
		}
		return axios
			.post(this.urlApi + path, formData, { headers: { ApiKey: this.apiKey }, withCredentials: true })
			.then(
				(response) => {
					return response;
				},
				(error) => {
					return error.response;
				}
			)
			.catch(function (error) {
				console.log(error);
			});
	},

	put(path, data) {
		return axios
			.put(this.urlApi + path, data, { headers: { ApiKey: this.apiKey } })
			.then(
				(response) => {
					return response;
				},
				(error) => {
					return error.response;
				}
			)
			.catch(function (error) {
				console.log(error);
			});
	},

	setApiKey(apiKey) {
		this.apiKey = apiKey;
	},
};
