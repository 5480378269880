import {
    Home,
    Box,
	Folder,
    DollarSign,
    Tag,
    Clipboard,
    Camera,
    AlignLeft,
    UserPlus,
    Users,
    Chrome,
    BarChart,Settings,Archive, LogIn
} from 'react-feather';

export const MENUITEMS = [
    {
        path: '/', title: 'Dashboard', icon: Home, type: 'link', badgeType: 'primary', active: false
    },
	{
		title: 'Catégories', icon: Folder, type: 'sub', active: false, children: [
			{ path: '/categories/list', title: 'Liste des catégories', type: 'link' },
			{ path: '/categories/create', title: 'Ajouter une catégorie', type: 'link' },
		]
	},
	{
		title: 'Pages', icon: Clipboard, type: 'sub', active: false, children: [
			{ path: '/pages/list', title: 'Liste des pages', type: 'link' },
			{ path: '/pages/create', title: 'Ajouter une page', type: 'link' },
		]
	},
	{
		title: 'Products', icon: Box, type: 'sub', active: false, children: [
			{ path: '/products/attributes', icon: Clipboard, title: 'Attributs des produits', type: 'link' },
			{ path: '/products/types', icon: Box, title: 'Types des produits', type: 'link' },
			{ path: '/products/add', title: 'Ajouter un produit', type: 'link' },
		]
	},

	/*
    {
        title: 'Products', icon: Box, type: 'sub', active: false, children: [
            {
                title: 'Physical', type: 'sub', active: false, children: [
                    { path: '/products/physical/category', title: 'Category', type: 'link' },
                    { path: '/products/physical/sub-category', title: 'Sub Category', type: 'link' },
                    { path: '/products/physical/product-list', title: 'Product List', type: 'link' },
                    { path: '/products/physical/product-detail', title: 'Product Detail', type: 'link' },
                    { path: '/products/physical/add-product', title: 'Add Product', type: 'link' },
                ]
            },
            {
                title: 'digital', type: 'sub', active: false, children: [
                    { path: '/products/digital/digital-category', title: 'Category', type: 'link' },
                    { path: '/products/digital/digital-sub-category', title: 'Sub Category', type: 'link' },
                    { path: '/products/digital/digital-product-list', title: 'Product List', type: 'link' },
                    { path: '/products/digital/digital-add-product', title: 'Add Product', type: 'link' },
                ]
            },
        ]
    },
    {
        title: 'Sales', icon: DollarSign, type: 'sub', active: false, children: [
            { path: '/sales/orders', title: 'Orders', type: 'link' },
            { path: '/sales/transactions', title: 'Transactions', type: 'link' },
        ]
    },
    {
        title: 'Coupons', icon: Tag, type: 'sub', active: false, children: [
            { path: '/coupons/list-coupons', title: 'List Coupons', type: 'link' },
            { path: '/coupons/create-coupons', title: 'Create Coupons', type: 'link' },
        ]
    },
    {
        title: 'Media', path: '/media', icon: Camera, type: 'link', active: false
    },
    {
        title: 'Menus', icon: AlignLeft, type: 'sub', active: false, children: [
            { path: '/menus/list-menu', title: 'List Menu', type: 'link' },
            { path: '/menus/create-menu', title: 'Create Menu', type: 'link' },
        ]
    },
	 */
    {
        title: 'Utilisateurs', icon: UserPlus, type: 'sub', active: false, children: [
            { path: '/users/list', title: 'Liste des utilisateurs', type: 'link' },
            { path: '/users/create', title: 'Ajouter un utilisateur', type: 'link' },
        ]
    }
]
