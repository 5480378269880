import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import './index.scss';
import App from './components/app';
import {ScrollContext} from 'react-router-scroll-4';

// Components
import {ToastContainer} from "react-toastify";
import Dashboard from './components/dashboard';

// Products physical
import Category from './components/products/physical/category';
import Sub_category from './components/products/physical/sub-category';
import Product_list from './components/products/physical/product-list';
import Add_product from './components/products/physical/add-product';
import Product_detail from './components/products/physical/product-detail';

//Product Digital
import Digital_category from './components/products/digital/digital-category';
import Digital_sub_category from './components/products/digital/digital-sub-category';
import Digital_pro_list from './components/products/digital/digital-pro-list';
import Digital_add_pro from './components/products/digital/digital-add-pro';

//Sales
import Orders from './components/sales/orders';
import Transactions_sales from './components/sales/transactions-sales';

//Coupons
import ListCoupons from './components/coupons/list-coupons';
import Create_coupons from './components/coupons/create-coupons';

//Pages
import Media from './components/media/media';
import List_menu from './components/menus/list-menu';
import Create_menu from './components/menus/create-menu';
import List_vendors from './components/vendors/list-vendors';
import Create_vendors from './components/vendors/create.vendors';
import Translations from './components/localization/translations';
import Rates from './components/localization/rates';
import Taxes from './components/localization/taxes';
import Profile from './components/settings/profile';
import Reports from './components/reports/report';
import Invoice from './components/invoice';
import Datatable from './components/common/datatable'
import Login from './views/pages/login';
import {PrivateRoute} from "./services/AuthService";

// Reducers
import rootReducer from "./reducers";
import { createStore } from "redux";
import {Provider} from "react-redux";

// Pages
import UserList from "./views/pages/users/list";
import UserAdd from "./views/pages/users/add";
import UserEdit from "./views/pages/users/edit";
import CategoryAdd from "./views/pages/categories/add";
import CategoryList from "./views/pages/categories/list";
import CategoryEdit from "./views/pages/categories/edit";
import PageAdd from "./views/pages/pages/add";
import PageList from "./views/pages/pages/list";
import PageEdit from "./views/pages/pages/edit";
import ProductTypeList from "./views/pages/products/types/list";
import ProductTypeAdd from "./views/pages/products/types/add";
import ProductTypeEdit from "./views/pages/products/types/edit";
import AttributeList from "./views/pages/products/attributes/list";
import AttributeAdd from "./views/pages/products/attributes/add";
import AttributeEdit from "./views/pages/products/attributes/edit";
import ProductAdd from "./views/pages/products/add";

const store = createStore(rootReducer);

export default class BackEnd extends Component {
	render() {
		return (
			<Provider store={store}>
			<BrowserRouter basename={'/admin'}>
				<ScrollContext>
					<Switch>
						<Route exact path={`/login`} component={Login}/>
						<PrivateRoute path='/' component={() =>
							<App>
								<Route exact path={`/`} component={Dashboard}/>

								{/** Utilisateurs **/}
								<Route path={`/users/list`} component={UserList}/>
								<Route path={`/users/create`} component={UserAdd}/>
								<Route path={`/users/edit/:id`} component={UserEdit}/>

								{/** Categories **/}
								<Route path={`/categories/list`} component={CategoryList}/>
								<Route path={`/categories/create`} component={CategoryAdd}/>
								<Route path={`/categories/edit/:id`} component={CategoryEdit}/>

								{/** Pages **/}
								<Route path={`/pages/list`} component={PageList}/>
								<Route path={`/pages/create`} component={PageAdd}/>
								<Route path={`/pages/edit/:id`} component={PageEdit}/>

								{/** Products **/}
								<Route path={`/products/add`} exact component={ProductAdd}/>
								<Route path={`/products/types`} exact component={ProductTypeList}/>
								<Route path={`/products/types/create`} exact component={ProductTypeAdd}/>
								<Route path={`/products/types/edit/:id`} exact component={ProductTypeEdit}/>
								<Route path={`/products/attributes`} exact component={AttributeList}/>
								<Route path={`/products/attributes/create`} exact component={AttributeAdd}/>
								<Route path={`/products/attributes/edit/:id`} exact component={AttributeEdit}/>


								<Route path={`/products/physical/category`} component={Category}/>
								<Route path={`/products/physical/sub-category`} component={Sub_category}/>
								<Route path={`/products/physical/product-list`} component={Product_list}/>
								<Route path={`/products/physical/product-detail`} component={Product_detail}/>
								<Route path={`/products/physical/add-product`} component={Add_product}/>

								<Route path={`/products/digital/digital-category`} component={Digital_category}/>
								<Route path={`/products/digital/digital-sub-category`}
									   component={Digital_sub_category}/>
								<Route path={`/products/digital/digital-product-list`} component={Digital_pro_list}/>
								<Route path={`/products/digital/digital-add-product`} component={Digital_add_pro}/>

								<Route path={`/sales/orders`} component={Orders}/>
								<Route path={`/sales/transactions`} component={Transactions_sales}/>

								<Route path={`/coupons/list-coupons`} component={ListCoupons}/>
								<Route path={`/coupons/create-coupons`} component={Create_coupons}/>

								<Route path={`/media`} component={Media}/>

								<Route path={`/menus/list-menu`} component={List_menu}/>
								<Route path={`/menus/create-menu`} component={Create_menu}/>



								<Route path={`/vendors/list_vendors`} component={List_vendors}/>
								<Route path={`/vendors/create-vendors`} component={Create_vendors}/>

								<Route path={`/localization/transactions`} component={Translations}/>
								<Route path={`/localization/currency-rates`} component={Rates}/>
								<Route path={`/localization/taxes`} component={Taxes}/>

								<Route path={`/reports/report`} component={Reports}/>

								<Route path={`/settings/profile`} component={Profile}/>

								<Route path={`/invoice`} component={Invoice}/>

								<Route path={`/data-table`} component={Datatable}/>

							</App>
						}/>
					</Switch>
				</ScrollContext>
			</BrowserRouter>
				<ToastContainer/>
			</Provider>
		)
	}
}


