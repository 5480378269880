import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom'
import Breadcrumb from '../../../../components/common/breadcrumb';
import Datatable from '../../../../components/common/datatable'
import {connect} from "react-redux";
import {toast} from "react-toastify";
import {ApiService} from "../../../../services/ApiService";
import {typesAttribute} from "../../../../variables";

const _ = require('lodash');

export class AttributeList extends Component {

	state = {
		attributes: this.props.attributes
	}

	componentWillReceiveProps = (nextProps)  => {
		this.setState({
			attributes: nextProps.attributes
		});
	}

	buildDatabase = (attributes) => {
		return attributes.map((attribute) => {
			return {
				id: attribute.id,
				name: attribute.name,
				code: attribute.code,
				type: typesAttribute.find((type) => type.value === attribute.type).label
			};
		})
	}

	deleteAttribute = (attribute) => {
		if(window.confirm('Etes-vous sur de vouloir supprimer cette attribut ?')) {
			ApiService.delete(`/admin/attribute/${attribute.id}`).then((res) => {
				if (res.status === 202) {
					this.props.removeAttribute(attribute.id);
					toast.success("L'attribut a bien été supprimer !")
				} else toast.error("Une erreur est survenu");
			});
		}
	}

    render() {
		const attributes = _.cloneDeep(this.state.attributes);
        return (
            <Fragment>
                <Breadcrumb title="Liste des attributes" parent="Products" />
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header">
                            <h5>Détails des attributes</h5>
                        </div>
                        <div className="card-body">
                            <div className="btn-popup pull-right">
                                <Link to="/products/attributes/create" className="btn btn-secondary">Créer un attribut</Link>
                            </div>
                            <div className="clearfix"/>
                            <div id="batchDelete" className="category-table user-list order-table coupon-list-delete">
                                <Datatable
                                    multiSelectOption={false}
                                    myData={this.buildDatabase(attributes)}
                                    pageSize={10}
									pathEdit={'/products/attributes/edit/'}
									delete={this.deleteAttribute}
                                    pagination={true}
                                    class="-striped -highlight"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
	return { attributes: state.attributes };
};

const mapDispatchToProps = (dispatch) => ({
	removeAttribute: (id) => dispatch({ type: "ATTRIBUTE_REMOVE", id }),
});

export default connect(mapStateToProps, mapDispatchToProps)(AttributeList);
