import React, { Component,Fragment } from 'react'
import Breadcrumb from '../../../components/common/breadcrumb';
import user from "../../../assets/images/user.png";
import {ApiService} from "../../../services/ApiService";
import {InputText, InputPassword, InputEmail} from "../../../components/form";
import {connect} from "react-redux";
import {toast} from "react-toastify";

export class UserEdit extends Component {

	state = {
		form : this.props.user,
		avatar : this.props.user.avatar ? this.props.user.avatar : user,
	}

	handleChange = (e) => {
		const { form } = this.state;
		form[e.target.id] = e.target.value;
		this.setState({ form });
	}

	_handleImgChange(e) {
		e.preventDefault();
		let reader = new FileReader();
		let file = e.target.files[0];
		const { form } = this.state;
		reader.onloadend = () => {
			form.avatar = file;
			const avatar = reader.result;
			this.setState({ form, avatar });
		}
		reader.readAsDataURL(file)
	}

	submitForm = (e) => {
		e.preventDefault();
		ApiService.post('/admin/user/' + this.props.user.id, this.state.form).then((res) => {
			if(res.status === 202) {
				this.props.editUser(user);
				toast.success("L'utilisateur a bien été modifier !");
			} else if (res.status === 400) {
				this.setState({errors: res.data.errors})
			}
		});
	}

	cancel = () => {
		window.history.back();
	}

    render() {
        return (
            <Fragment>
                <Breadcrumb title="Ajouter un utilisateur" parent="Utilisateurs" />
				<form onSubmit={this.submitForm}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-8 col-sm-12">
                            <div className="card">
                                <div className="card-header">
									<h5>Détails du compte</h5>
                                </div>
                                <div className="card-body">
									<InputText
										id={"username"}
										label={'Login'}
										value={this.state.form['username']}
										required={true}
										errors={this.state.errors}
										change={this.handleChange} />
									<InputPassword id={"password"} label={'Mot de passe'} errors={this.state.errors} change={this.handleChange} />
                                </div>
                            </div>
                        </div>
						<div className="col-xl-4 col-sm-12">
							<div className="card">
								<div className="card-header">
									<h5>Avatar</h5>
								</div>
								<div className="card-body">
									<div className="form-group row">
										<div className="col-12 text-center">
											<img src={this.state.avatar} className={"align-self-center rounded-circle blur-up lazyloaded"} style={{ width: 50, height: 50 }} />
										</div>
									</div>
									<div className="form-group row">
										<div className="col-12">
											<div className="box-input-file text-center">
												<input className="upload" type="file" accept="image/*" onChange={(e) => this._handleImgChange(e)} />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-8 col-sm-12">
							<div className="card">
								<div className="card-header">
									<h5>Information générale</h5>
								</div>
								<div className="card-body">
									<InputText
										id={"firstname"}
										label={"Prénom"}
										value={this.state.form['firstname']}
										required={true}
										errors={this.state.errors}
										change={this.handleChange} />
									<InputText
										id={"name"}
										label={"Nom"}
										required={true}
										value={this.state.form['name']}
										errors={this.state.errors}
										change={this.handleChange} />
									<InputText
										id={"job"}
										label={"Nom du poste"}
										value={this.state.form['job']}
										required={true}
										errors={this.state.errors}
										change={this.handleChange} />
									<InputEmail
										id={"email"}
										label={"Email"}
										required={true}
										value={this.state.form['email']}
										errors={this.state.errors}
										change={this.handleChange} />
								</div>
							</div>
						</div>
						<div className="offset-xl-3 offset-sm-4">
							<button type="submit" className="btn btn-primary">Modifier</button>
							<button type="button" className="btn btn-light" onClick={() => this.cancel()}>Annuler</button>
						</div>
                    </div>
                </div>
				</form>
            </Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
	return { user: state.users.find(user => user.id === parseInt(ownProps.match.params.id)) };
};

const mapDispatchToProps = (dispatch) => ({
	editUser: (user) => dispatch({ type: "USERS_EDIT", user }),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserEdit);
