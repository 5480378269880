import React, {Component, Fragment} from 'react'
import Breadcrumb from '../../../components/common/breadcrumb';
import {ApiService} from "../../../services/ApiService";
import {InputText, SingleSelect, Wysiwyg} from "../../../components/form";
import {toast} from "react-toastify";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";
import DatePicker from "react-datepicker";
import moment from "moment";

export class PageAdd extends Component {

	state = {
		form: {visibility: 1, date: moment().format("YYYY-MM-DD")},
		image: false,
		redirect: false
	}

	handleChange = (e) => {
		const {form} = this.state;
		form[e.target.id] = e.target.value;
		if (e.target.id === 'title') {
			form['slug'] = this.slug(e.target.value);
		}
		this.setState({form});
	}


	handleChangeDate = (date) => {
		const {form} = this.state;
		form['date'] = moment(date).format("YYYY-MM-DD");
		this.setState({form});
	}

	handleChangeImage = (file) => {
		const {form} = this.state;
		form.image = file;
		this.setState({form});
	}

	submitForm = (e) => {
		e.preventDefault();
		ApiService.post('/admin/page', this.state.form).then((res) => {
			if (res.status === 202) {
				toast.success("La page a bien été créer !")
				this.props.addPage(res.data);
				this.setState({redirect: true})
			} else if (res.status === 400) {
				this.setState({errors: res.data.errors})
			}
		});
	}

	listType = () => {
		return [
			{value: 1, label: 'Page CMS'},
			{value: 2, label: 'PV'}
		];
	};

	handleChangeType = (parent) => {
		const {form} = this.state;
		form['type'] = parent.value;
		this.setState({form});
	};

	handleChangeVisibility = (visibility) => {
		const {form} = this.state;
		form['visibility'] = visibility;
		this.setState({form});
	};

	handleChangeContent = (e) => {
		const {form} = this.state;
		form['content'] = e.editor.getData();
		this.setState({form});
	}

	getParentNode = () => {
		const typeList = [
			{value: 1, label: 'Page CMS'},
			{value: 2, label: 'PV'}
		]
		return typeList.find((type) => type.value === this.state.form.type);
	};

	cancel = () => {
		window.history.back();
	}

	slug = (str) => {
		str = str.replace(/^\s+|\s+$/g, ''); // trim
		str = str.toLowerCase();

		// remove accents, swap ñ for n, etc
		var from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
		var to = "aaaaaeeeeeiiiiooooouuuunc------";
		for (var i = 0, l = from.length; i < l; i++) {
			str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
		}

		str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
			.replace(/\s+/g, '-') // collapse whitespace and replace by -
			.replace(/-+/g, '-'); // collapse dashes

		return str;
	};

	render() {

		if (this.state.redirect)
			return (<Redirect to="/pages/list"/>);
		else
			return (
				<Fragment>
					<Breadcrumb title="Ajouter une page" parent="Pages"/>
					<form onSubmit={this.submitForm}>
						<div className="container-fluid">
							<div className="row">
								<div className="col-xl-9 offset-xl-1 col-sm-12">
									<div className="card">
										<div className="card-header">
											<h5>Détails de la page</h5>
										</div>
										<div className="card-body">
											<SingleSelect
												id='type'
												options={this.listType()}
												value={this.getParentNode()}
												change={this.handleChangeType}
												errors={this.state.errors}
												label="Type de la page"
												placeholder="Sélectionner le type de page"
											/>
											<InputText id={"title"} label={'Titre'} required={true}
													   errors={this.state.errors} change={this.handleChange}/>
											<InputText id={"slug"} label={'Slug'} required={true}
													   value={this.state.form.slug}
													   errors={this.state.errors} change={this.handleChange}/>
											{this.state.form.type === 2 &&
											<div className="form-group row">
												<label
													className={"col-form-label col-xl-3 col-md-4"}>Date du PV</label>
												<div className={"col-xl-8 col-md-7"}>
													<DatePicker
														id={"date"}
														dateFormat="dd-MM-yyyy"
														selected={new Date(this.state.form.date)}
														onChange={this.handleChangeDate}/>
												</div>
											</div>}
											<div className="form-group row">
												<label className="col-form-label col-xl-3">Visible</label>
												<div className="col-xl-4 m-checkbox-inline">
													<label className="col-6">
														<input className="radio_animated"
															   onClick={() => this.handleChangeVisibility(1)}
															   defaultChecked={this.state.form.visibility}
															   id="visibility" type="radio" name="visibility"/>
														Oui
													</label>
													<label className="col-6">
														<input className="radio_animated"
															   onClick={() => this.handleChangeVisibility(0)}
															   defaultChecked={!this.state.form.visibility}
															   id="visibility" type="radio"
															   name="visibility"/>
														Non
													</label>
												</div>
											</div>
											<Wysiwyg
												id={'content'}
												label={'Contenu de la page'}
												value={this.state.form.content}
												errors={this.state.errors}
												change={this.handleChangeContent}/>
										</div>
										<div className="card-footer">
											<div className="text-center">
												<button type="submit" className="btn btn-primary">Ajouter</button>
												<button type="button" className="btn btn-light"
														onClick={() => this.cancel()}>Annuler
												</button>
											</div>
										</div>
									</div>
								</div>

							</div>
						</div>
					</form>
				</Fragment>
			)
	}
}

const mapDispatchToProps = (dispatch) => ({
	addPage: (page) => dispatch({type: "PAGE_ADD", page}),
});


export default connect(null, mapDispatchToProps)(PageAdd);
